<template>
    <b-form-select class="shadow-sm"
                   v-model="region">
        <option v-for="{ _id, code, name } in regions"
                v-bind:key="_id"
                v-bind:value="code">
            {{ name }}
        </option>
    </b-form-select>
</template>

<script>
import { BFormSelect, } from "bootstrap-vue";
export default {
    name: "RegionSelect",
    components: {
        BFormSelect,
    },
    data () {
        return {

        };
    },
    computed: {
        region: {
            get () {
                return this.$route.params?.region ?? "HKG";
            },
            set (val) {
                this.$router.push({
                    "name": this.$route.name,
                    "params": { ...this.$route.params, "region": val, },
                });
            },
        },
        regions () {
            return this.$store.getters["region/itemsActive"];
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
