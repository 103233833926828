<template>
    <section class="events container-fluid d-flex flex-column h-100 p-3">
        <div class="row h-100 m-0">
            <div class="col-lg-7 col-xl-8 h-100 overflow-auto">
                <div class="position-sticky title d-flex flex-nowrap align-items-center mb-2">
                    <div class="d-flex d-xl-none align-items-center">
                        <button-toggle-side-bar></button-toggle-side-bar>
                    </div>
                    <h1 class="font-weight-bolder col pl-xl-0 mb-0">
                        Activities
                    </h1>
                    <div class="d-flex align-items-center">
                        <region-select class="mr-2"></region-select>
                        <b-form-select class="shadow-sm mr-2"
                                       v-model="calendarView">
                            <option value="month">Monthly</option>
                            <option value="week">Weekly</option>
                            <option value="day">Daily</option>
                        </b-form-select>
                        <button class="btn btn-light border text-nowrap shadow-sm mr-2"
                                v-on:click="isModalCategoriesOpen = true">
                            🏷️ Categories
                        </button>
                        <button class="btn btn-light border text-nowrap shadow-sm"
                                v-on:click="isModalAddEventOpen = true">
                            ✨ New Activity
                        </button>
                    </div>
                </div>
                <vue-cal class="calendar rounded-xl shadow-sm border overflow-hidden"
                         ref="vuecal"
                         small
                         show-week-numbers
                         hide-view-selector
                         default-view="week"
                         start-week-on-sunday
                         events-on-month-view="short"
                         watch-real-time
                         disable-date-prototypes
                         v-bind:active-view.sync="calendarView"
                         v-bind:disable-views="['years', 'year']"
                         v-bind:events="events"
                         v-bind:on-event-click="onEventClick"
                         v-on:ready="onViewChange"
                         v-on:view-change="onViewChange">
                </vue-cal>
            </div>
            <div class="col-lg-5 col-xl-4 mx-3 mx-lg-0 p-lg-0 h-100">
                <router-view v-bind:start="calendarStart"
                             v-bind:end="calendarEnd"
                             v-bind:activities="calendarActivities">
                </router-view>
            </div>
        </div>

        <modal-activity v-model="isModalAddEventOpen"></modal-activity>
        <modal-activity-categories v-model="isModalCategoriesOpen"></modal-activity-categories>
    </section>
</template>

<script>
import { formatISO9075, } from "date-fns";
import { BFormSelect, } from "bootstrap-vue";
import VueCal from "vue-cal";
import "vue-cal/dist/drag-and-drop"
import "vue-cal/dist/vuecal.css";
import RegionSelect from "@/components/layout/RegionSelect";
export default {
    name: "Activities",
    metaInfo () {
        return {
            "title": "Activities",
        };
    },
    components: {
        RegionSelect,
        BFormSelect,
        VueCal,
        ButtonToggleSideBar: () => import("@/components/layout/ButtonToggleSideBar"),
        ModalActivity: () => import("@/components/Activities/ModalActivity"),
        ModalActivityCategories: () => import("@/components/Activities/ModalActivityCategories"),
    },
    data () {
        return {
            isModalAddEventOpen: false,
            isModalCategoriesOpen: false,

            calendarView: "week", // viable values: month, week, day
            calendarStart: null,
            calendarEnd: null,
            calendarActivities: [],
        };
    },
    computed: {
        events () {
            return this.$store.getters["event/items"]
                .map(
                    ({ _id, status, startDate, startTime, endDate, endTime, title, description, isFree, isPromoted, isFull, }) => {
                        const { start, end, } = (() => {
                            // More than one day
                            if (
                                startDate && startTime &&
                                endDate && endTime
                            ) {
                                return {
                                    "start": `${
                                        formatISO9075(new Date(startDate), { representation: "date" })
                                    } ${
                                        formatISO9075(new Date(startTime), { representation: "time" })
                                    }`,
                                    "end": `${
                                        formatISO9075(new Date(endDate), { representation: "date" })
                                    } ${
                                        formatISO9075(new Date(endTime), { representation: "time" })
                                    }`
                                };
                            }

                            // Ordinary same day with end
                            if (startDate && startTime && endTime) {
                                return {
                                    "start": `${
                                        formatISO9075(new Date(startDate), { representation: "date" })
                                    } ${
                                        formatISO9075(new Date(startTime), { representation: "time" })
                                    }`,
                                    "end": `${
                                        formatISO9075(new Date(startDate), { representation: "date" })
                                    } ${
                                        formatISO9075(new Date(endTime), { representation: "time" })
                                    }`
                                };
                            }

                            // Ordinary same day without end
                            if (startDate && startTime) {
                                return {
                                    "start": `${
                                        formatISO9075(new Date(startDate), { representation: "date" })
                                    } ${
                                        formatISO9075(new Date(startTime), { representation: "time" })
                                    }`,
                                    "end": `${
                                        formatISO9075(new Date(startDate), { representation: "date" })
                                    } 23:59:59`
                                };
                            }

                            // All-day activity
                            if (startDate) {
                                return {
                                    "start": formatISO9075(new Date(startDate), { representation: "date" }),
                                    "end": formatISO9075(new Date(startDate), { representation: "date" })
                                };
                            }
                            throw "incorrect format";
                        })();
                        return {
                            "eventId": _id,
                            "content": "",
                            "class": "bg-light text-dark border-top border-bottom cal-activity",
                            status,
                            start, end,
                            title, description,
                            isFree, isPromoted, isFull,
                        };
                    }
                );
        },
    },
    methods: {
        onEventClick (evt) {
            this.$router.push(`/activities/${ evt.eventId }`);
        },
        onViewChange ({ startDate, endDate, events, }) {
            this.calendarStart = startDate;
            this.calendarEnd = endDate;
            this.calendarActivities = events;
        },
    },
    watch: {
        events (newVal) {
            if (
                Array.isArray(newVal) &&
                newVal.length > 0
            ) {
                const temp = this.calendarView;
                this.calendarView = "day";
                this.$nextTick(
                    () => this.calendarView = temp
                );
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.events {
    .title {
        top: 0;
        background-color: rgba(255, 255, 255, 1);
        z-index: 3;
    }

    .calendar {
        height: calc(100% - 56px);
    }
}
</style>

<style lang="scss">
.events {
    .vuecal__title-bar {
        font-size: 1rem;
    }

    .vuecal__event {
        &.cal-event {
            cursor: pointer;
        }
    }
}
</style>
